<template>
  <div
    v-if="isShowOverlay"
    class="overlay"
    @click="hide()"
  >
    <div class="overlay-container">
      <slot />
      <div
        class="overlay-close"
        @click="hide()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Overlay',
  mounted () {
    this.show()
  },
  data () {
    return {
      isShowOverlay: false,
    }
  },
  methods: {
    show () {
      this.isShowOverlay = true
    },
    hide () {
      this.isShowOverlay = false
    },
  },
}
</script>

<style lang="scss" scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  visibility: visible;
  transition: 0.3s linear;
}
.overlay-container {
  position: relative;
}
.overlay-close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  color: #fff;
  padding: 16px 16px;
}
.overlay-close:before,
.overlay-close:after {
  position: absolute;
  left: 21px;
  top: 14px;
  content: ' ';
  height: 18px;
  width: 1px;
  background-color: #fff;
}
.overlay-close:before {
  transform: rotate(45deg);
}
.overlay-close:after {
  transform: rotate(-45deg);
}
</style>
