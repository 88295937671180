import * as routeConfig from '@/router/config'
const DLDCampaign = () => import('@/pages/dld/Campaign' /* webpackChunkName: "dld" */)
const Dld2w23q1 = () => import('@/pages/dld/23q1Dld2w' /* webpackChunkName: "dldcampaign" */)
const MotorShowCampaign = () => import('@/pages/dld/Motorshow' /* webpackChunkName: "dldcampaign-motorshow" */)

export const makeRoute = features => {
  return [
    {
      path: '',
      name: 'Dld',
      redirect: {
        name: 'DLDCampaign',
      },
    },
    {
      path: 'campaign',
      name: 'DLDCampaign',
      component: DLDCampaign,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: '23q1-dld2w',
      name: 'Dld2w23q1',
      component: Dld2w23q1,
      beforeEnter: (to, from, next) => {
        if (features.DLD_CAMPAIGN) {
          next()
        } else {
          next({
            name: 'pageNotFound',
          })
        }
      },
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
    {
      path: '24q1-motorshow',
      name: 'MotorShowCampaign',
      component: MotorShowCampaign,
      meta: {
        routeConfig: routeConfig.allowAllRouteConfig,
      },
    },
  ]
}
