<template>
  <div id="app">
    <div
      v-if="lineLoading"
      class="loading"
    >
      <div class="wrap-item">
        <img
          class="loading-cloud"
          :src="require(`@/assets/images/line-ka/loading-cloud.png`)"
          alt="loading"
        >
        <!-- <div class="gif-image">
          <img class="loading-gif" :src="require(`@/assets/images/line-ka/loading-line-1.png`)" alt="loading" />
          <img class="loading-gif" :src="require(`@/assets/images/line-ka/loading-line-2.png`)" alt="loading" />
          <img class="loading-gif" :src="require(`@/assets/images/line-ka/loading-line-3.png`)" alt="loading" />
        </div> -->
        <span class="check">กำลังตรวจสอบข้อมูล</span>
        <span>วงเงินพร้อมสตาร์ท</span>
      </div>
    </div>
    <!-- <div
      v-if="isLineError"
      class=""
    >
      {{ this.$store.state.lineka.isError }}
    </div> -->

    <template v-if="isTemplateFullScreenWithOutHeaderAndFooter">
      <router-view />
    </template>
    <template v-else>
      <Overlay v-if="isOverlaySpecialDay">
        <!-- ***** don't rename image name ***** -->
        <ImageSvg />
      </Overlay>
      <ToastNotification />
      <Header
        v-show="isShowHeaderFooter"
        :is-error-page="isError"
      />
      <template v-if="!isOutOfService">
        <template v-if="isNoDoc">
          <div
            class="home-wrapper"
            :class="[getWrapperDesktopClass(), {'home-wrapper-clear': getQueryMobile, 'warpper-with-blogs': isShowBlogs}]"
          >
            <router-view />
          </div>
        </template>
        <template v-else>
          <div
            :class="{
              'pre-wrapper': !isDesktopLanding && !isDealList && !isEFormDesktop && !isFaq,
              'pre-wrapper-deallist': isDealList,
            }"
          >
            <div
              :class="{
                wrapper: !isHome && !isDesktopLanding && !isDealList && !isEFormDesktop && !isFinancialInfo && !isRiskQuestion && !isOtp && !isPreApproved && !isSaleSheet,
                faq: isFaq,
              }"
            >
              <router-view />
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div style="margin-bottom: 56px;">
          <OutOfService />
        </div>
      </template>
      <Footer
        v-if="!isError"
        v-show="isShowHeaderFooter"
        :collapse-active="getRoutePath"
      />
    </template>
  </div>
</template>

<script>
import * as api from '@/api'
import _ from 'lodash'

import ToastNotification from '@/components/pages/ToastNotification'
import Footer from '@/components/layout/FooterPage'
import Header from '@/components/layout/HeaderPage'
import Overlay from '@/components/overlay/Overlay'
import OutOfService from '@/components/pages/common/OutOfService.vue'
import {checkPeriodTime} from '@/utilities/date'
import jsonConfig from '@/assets/resources/special-day'
import ImageSvg from '@/components/image-special-day/ImageSvg.vue'

export default {
  name: 'App',
  provide: {api, features: window.features, captcha: window.captcha, amplitude: window.amplitude},
  metaInfo: {
    title: 'ออกรถใหม่ เลือกรถราคาเท่าไหร่ดี',
    titleTemplate: '%s | กรุงศรี ออโต้ พร้อมสตาร์ท',
    htmlAttrs: {
      lang: 'th',
    },
    meta: [{name: 'keywords', content: 'เช็กวงเงิน, ออกรถใหม่, อนุมัติไว, รู้ผลเลย'}],
  },
  components: {
    ToastNotification,
    Footer,
    Header,
    Overlay,
    OutOfService,
    ImageSvg,
  },
  data () {
    return {
      isShowHeaderFooter: false,
      isOverlaySpecialDay: false,
      dateConfig: jsonConfig,
      currentDate: Date.now(),
    }
  },
  mounted () {
    let cookieValue = this.$cookies.get('SPECIAL_DAY_SPLASH_PAGE')
    const isCookiePeriodTime = checkPeriodTime(this.dateConfig.startDate, this.dateConfig.endDate, parseInt(cookieValue) || 0)
    const isPresentTime = checkPeriodTime(this.dateConfig.startDate, this.dateConfig.endDate, this.currentDate)

    if (!isCookiePeriodTime) {
      this.$cookies.remove('SPECIAL_DAY_SPLASH_PAGE')
      cookieValue = null
    }

    if (cookieValue == null && isPresentTime) {
      this.isOverlaySpecialDay = true
      this.$cookies.set('SPECIAL_DAY_SPLASH_PAGE', Date.now())
    }
  },
  watch: {
    $route () {
      this.isShowHeaderFooter = !this.getQueryMobile
    },
  },
  computed: {
    lineLoading () {
      return this.$store.state.lineka.loading
    },
    isLineError () {
      return this.$store.state.lineka.isError
    },
    getQueryMobile () {
      return _.get(this.$route, 'query.mobile', 'false') === 'true' || _.get(this.$route, 'query.partner', '') === 'line'
    },
    getRoutePath () {
      return this.$route.path === '/' || this.$route.name === 'homePage'
    },
    isDesktopLanding () {
      return this.$route.name === 'desktopLanding' || this.$route.name === 'desktopHomePage'
    },
    isHome () {
      return this.$route.name === 'home' || this.$route.name === 'homePage'
    },
    isError () {
      return _.includes(['pageNotFound', 'catchAll'], this.$route.name)
    },
    isDealList () {
      return this.$route.name === 'DealList' || this.$route.name === 'DealListExclusive'
    },
    isSaleSheet () {
      return this.$route.name === 'SaleSheet'
    },
    isEFormDesktop () {
      return this.$route.name === 'eFormDesktopThankYou' || this.$route.name === 'desktopEFormSending'
    },
    isFinancialInfo () {
      return this.$route.name === 'FinancialInfo'
    },
    isRiskQuestion () {
      return this.$route.name === 'RiskQuestion'
    },
    isOtp () {
      return this.$route.name === 'SubmitOtp'
    },
    isFaq () {
      return this.$route.name === 'Faq'
    },
    isPreApproved () {
      return this.$route.name === 'PreApproval' || this.$route.name === 'ExclusivePreApproval'
    },
    isNoDoc () {
      return (
        this.$route.name === 'home' ||
        this.$route.name === 'desktopLanding' ||
        this.$route.name === 'homePage' ||
        this.$route.name === 'desktopHomePage' ||
        this.$route.name === 'productHighlight'
      )
    },
    isOutOfService () {
      if (window.features.OUT_OF_SERVICE) {
        return true
      }
      return false
    },
    isTemplateFullScreenWithOutHeaderAndFooter () {
      return this.$route.path.includes('/dld/') || this.$route.path.includes('/line-ka/') || this.$route.path.includes('/contents')
    },
    isShowBlogs () {
      return window.features.SEO_SESSION
    },
    isPartner () {
      return this.$route.path.includes('/partner/') || this.$route.path.includes('/line-ka/')
    },
  },
  methods: {
    getWrapperDesktopClass () {
      return this.$route.name === 'desktopLanding' || this.$route.name === 'desktopHomePage' ? 'clearWrapperPadding' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~styles/main';

#app {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.pre-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 580px;
  flex: 1;
}
.pre-wrapper-deallist {
  background-color: #f1efef;
}
.wrapper {
  padding: 10px 20px 30px;
  background-color: #fff;
  height: 100%;
}
.faq {
  padding: 0px;
}

.home-wrapper {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0 0 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;

  @media (max-width: 320px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  /* Media Query for Mobile Devices */
  @media (min-width: 321px) and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 550px;
  }

  /* Media Query for Laptops and Desktops */
  @media (min-width: 1025px) {
    max-width: 960px;
    padding-bottom: 0px;
  }
}

.home-wrapper-clear {
  padding-top: 0;
}

.clearWrapperPadding {
  padding-bottom: 0px;
}
.svg-container {
  svg {
    @media (max-width: 767px) {
      width: 260px;
      height: 260px;
    }
    @media (min-width: 768px) {
      width: 548px;
      height: 548px;
    }
  }
}
.warpper-with-blogs {
  padding-bottom: 0;
}
.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  background-color: #fff;
  .wrap-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 204px;
    img.loading-cloud {
      height: 190px;
      width: 251px;
    }
    span {
      color: #757575;
      margin-bottom: 8px;
      font-size: 18px;
    }
    .check {
      font-size: 20px;
      color: #444444;
      margin-top: 30px;
      font-weight: 700;
    }
  }
}
</style>
