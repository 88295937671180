import Vue from 'vue'
import Router from 'vue-router'
import {makeRoute as makeLoginRoute} from '@/app/login/route'
import {makeRoute as makeCrmRoute} from '@/router/crm-route'
import {makeRoute as makeDldRoute} from '@/router/dld-route'
import {makeRoute as makeLineOARoute} from '@/router/line-oa'
import {makeRoute as makeBlogRoute} from '@/router/blogs'
// import {makeRoute as makePartnerRoute} from '@/router/partner-route'
import * as routeConfig from '@/router/config'
import {selectProductGuard} from '@/router/Guard'
import {makeRoute as makeEFormRoute} from '@/components/pages/EForm/route'
import * as api from '@/api'

const Login = () => import('@/app/login/Login' /* webpackChunkName: "login" */)
const Error = () => import('@/components/pages/common/Error' /* webpackChunkName: "error" */)
const LoanUtilize = () => import('@/components/pages/common/LoanUtilize' /* webpackChunkName: "loan-utilize" */)
const UploadingPage = () => import('@/components/pages/common/UploadingPage' /* webpackChunkName: "uploading-page" */)
const Apologize = () => import('@/components/pages/common/Apologize' /* webpackChunkName: "apologies" */)
const LoanExpired = () => import('@/components/pages/common/LoanExpired')
const PageNotFound = () => import('@/components/pages/common/PageNotFound' /* webpackChunkName: "page-not-found" */)
const SubmissionFailed = () => import('@/components/pages/common/SubmissionFailed' /* webpackChunkName: "submission-failed" */)
const MissingData = () => import('@/components/pages/common/MissingData' /* webpackChunkName: "submission-failed" */)
const EForm = () => import('@/components/pages/EForm/EForm' /* webpackChunkName: "e-form" */)
const EFormStandard = () => import('@/components/pages/EForm/EFormStandard' /* webpackChunkName: "e-form-standard" */)
const DealList = () => import('@/pages/best-deal/DealList' /* webpackChunkName: "deal-list" */)
const DealDetail = () => import('@/pages/best-deal/DealDetail' /* webpackChunkName: "deal-detail" */)
const WaitingKaContact = () => import('@/pages/waiting-ka-contact/WaitingKaContact' /* webpackChunkName: "waiting-ka-contact" */)
const DesktopEFormSending = () => import('@/pages/home-desktop/components/eform/DesktopEFormSending' /* webpackChunkName: "eform-sending" */)
const Faq = () => import('@/pages/frequently-asked-questions/Faq' /* webpackChunkName: "FAQ page" */)

const Home = () => import('@/pages/home/Home' /* webpackChunkName: "home-no-doc" */)
const SelectProduct = () => import('@/pages/select-product/SelectProduct' /* webpackChunkName: "select-product" */)
const PersonalInfo = () => import('@/pages/personal-info/PersonalInfo' /* webpackChunkName: "personal-info" */)
const FinancialInfo = () => import('@/pages/financial-info/FinancialInfo' /* webpackChunkName: "financial-info" */)
const RiskQuestionV2 = () => import('@/pages/risk-question/RiskQuestion' /* webpackChunkName: "risk-question-v2" */)
const PreApprovalV2 = () => import('@/pages/pre-approval/PreApproval' /* webpackChunkName: "pre-approval" */)
const Otp = () => import('@/pages/otp/Otp' /* webpackChunkName: "otp" */)
const ThankYouForLoan = () => import('@/pages/thankyou/ThankYouForLoan' /* webpackChunkName: "thank-you-for-loan" */)
const EFormCRM = () => import('@/pages/eform/EFormCRM' /* webpackChunkName: "e-form-crm" */)

const Crm = () => import('@/pages/crm/Crm' /* webpackChunkName: "crm" */)
const Dld = () => import('@/pages/dld/Dld' /* webpackChunkName: "dld" */)
const LineOA = () => import('@/pages/line-oa/LineOA' /* webpackChunkName: "line-oa" */)

const Blogs = () => import('@/pages/blogs/Blogs' /* webpackChunkName: "contents" */)

// const Partner = () => import('@/pages/partner/Partner' /* webpackChunkName: "partner" */)

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

Vue.use(Router)
// Vue.component('vue-headful', vueHeadful)

const scrollBehavior = (to, from, savedPosition) => {
  // savedPosition is only available for popstate navigations.
  if (savedPosition) {
    return savedPosition
  }

  // Keep scroll position when navigate in the same page
  if (from.name === to.name) {
    return false
  }

  // Default go to top of the page
  return {
    x: 0,
    y: 0,
  }
}

export const makeRouter = ({store, features = {}, globalGuard}) => {
  const router = new Router({
    mode: 'history',
    scrollBehavior,
    routes: [
      {
        path: '/',
        name: 'home',
        redirect: {
          name: 'homePage',
        },
      },
      {
        path: '/home',
        name: 'homePage',
        component: Home,
        beforeEnter: async (to, from, next) => {
          await store.dispatch('carousel/refreshDealCarouselList', {api})
          if (from && from.name === 'error') {
            store.dispatch('noDocument/reset')
            store.dispatch('customerInformation/reset')
          }
          next()
        },
        meta: {
          routeConfig: routeConfig.preLoginRouteConfig,
        },
      },
      {
        path: '/',
        name: 'desktopLanding',
        redirect: {
          name: 'desktopHomePage',
        },
      },
      {
        path: '/contents',
        component: Blogs,
        children: makeBlogRoute(features),
      },
      {
        path: '/home',
        name: 'desktopHomePage',
        component: () => import(/* webpackChunkName: "desktop-landing" */ '@/pages/home-desktop/HomeDesktop'),
        meta: {
          routeConfig: {
            ...routeConfig.allowAllRouteConfig,
          },
        },
      },

      {
        path: '/eform-sending',
        name: 'desktopEFormSending',
        component: DesktopEFormSending,
        meta: {
          routeConfig: routeConfig.allowAllRouteConfig,
        },
      },
      {
        path: '/login',
        component: Login,
        children: makeLoginRoute({
          store,
          features,
        }),
      },
      // {
      //   path: '/partner',
      //   component: Partner,
      //   children: makePartnerRoute({
      //     store,
      //     features,
      //   }),
      // },
      {
        path: '/thankYou',
        name: 'thankYou',
        component: ThankYouForLoan,
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('noDocument/reset')
          store.dispatch('customerInformation/reset')
          store.dispatch('exclusive/reset')
          next()
        },
      },
      {
        path: '/apologize',
        name: 'apologize',
        component: Apologize,
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
      },
      {
        path: '/upload',
        name: 'UploadingPage',
        component: UploadingPage,
        meta: {
          routeConfig: routeConfig.noDirectRouteConfigNoDoc,
        },
      },
      {
        path: '/serviceNotAvailable',
        name: 'ServiceNotAvailablePage',
        component: () => import('@/components/pages/common/ServiceNotAvailablePage' /* webpackChunkName: "service-not-availabble" */),
        meta: {
          routeConfig: routeConfig.submissionFailedRouteConfig,
        },
      },
      {
        path: '/submissionFailed',
        name: 'SubmissionFailed',
        component: SubmissionFailed,
        meta: {
          routeConfig: routeConfig.submissionFailedRouteConfig,
        },
      },
      {
        path: '/missing-data',
        name: 'MissingData',
        component: MissingData,
        meta: {
          routeConfig: routeConfig.missingDataRouteConfigNoDoc,
        },
      },
      {
        path: '/preApproval',
        name: 'PreApproval',
        component: PreApprovalV2,
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
        beforeEnter: (to, from, next) => {
          const {partner} = to.query
          if (partner === 'line') {
            next({
              name: 'PartnerPreApproval',
              query: {
                partner: 'line',
              },
            })
          }
          next()
        },
      },
      {
        path: '/reject',
        name: 'Reject',
        component: () => import(/* webpackChunkName: "reject" */ '@/components/pages/common/Reject'),
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
      },
      {
        path: '/loanUtilize',
        name: 'LoanUtilize',
        component: LoanUtilize,
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
      },
      {
        path: '/loanExpired',
        name: 'LoanExpired',
        component: LoanExpired,
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
      },
      {
        path: '/applicationExpired',
        name: 'ApplicationExpired',
        component: LoanExpired,
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
      },
      {
        path: '/error/:id',
        name: 'error',
        component: Error,
        meta: {
          routeConfig: routeConfig.noDirectRouteConfigNoDoc,
        },
      },
      {
        path: '/notFound',
        name: 'pageNotFound',
        component: PageNotFound,
        meta: {
          routeConfig: routeConfig.allowAllRouteConfig,
        },
      },
      {
        path: '/eForm',
        component: EForm,
        children: makeEFormRoute({
          store,
          features,
        }),
      },
      {
        path: '/eFormStandard',
        name: 'EformStandard',
        component: EFormStandard,
        meta: {
          routeConfig: routeConfig.preLoginRouteConfig,
        },
      },
      {
        path: '/deallist',
        name: 'DealList',
        component: DealList,
        meta: {
          routeConfig: {
            ...routeConfig.dealRouteConfig,
            allowDesktop: features.DESKTOP_VERSION,
          },
          keepScroll: true,
          canonical: 'https://promptstart.krungsriauto.com/deallist',
        },
        beforeEnter: (to, from, next) => {
          store.commit('EForm/reset')
          next()
        },
      },
      {
        path: '/dealDetail/:campaignName',
        name: 'DealDetail',
        component: DealDetail,
        meta: {
          routeConfig: routeConfig.dealRouteConfig,
        },
      },
      {
        path: '/dealDetail/:campaignName/:subCampaign',
        name: 'SubDealDetail',
        component: DealDetail,
        meta: {
          routeConfig: routeConfig.dealRouteConfig,
        },
      },
      {
        path: '/waiting-ka-contact',
        name: 'WaitingKaContact',
        component: WaitingKaContact,
        meta: {
          routeConfig: routeConfig.noDocPostSubmissionRouteConfig,
        },
      },
      {
        path: '/frequently-asked-questions',
        name: 'Faq',
        component: Faq,
        meta: {
          routeConfig: routeConfig.allowAllRouteConfig,
        },
      },
      {
        path: '/select-product',
        name: 'SelectProductV2',
        component: SelectProduct,
        beforeEnter: selectProductGuard(store),
        meta: {
          routeConfig: routeConfig.preLoginRouteConfigNoDoc,
        },
      },
      {
        path: '/personal-information',
        name: 'PersonalInfo',
        component: PersonalInfo,
        beforeEnter: (to, from, next) => {
          const isAllowedEnter = store.getters['noDocument/product/isSelected']
          if (isAllowedEnter) {
            next()
          } else {
            next({
              name: 'SelectProductV2',
            })
          }
        },
        meta: {
          routeConfig: routeConfig.validateRefreshConfigMissingData,
        },
      },
      {
        path: '/financial-information',
        name: 'FinancialInfo',
        component: FinancialInfo,
        meta: {
          routeConfig: routeConfig.validateRefreshConfigMissingData,
        },
      },
      {
        path: '/risk-question',
        name: 'RiskQuestion',
        component: RiskQuestionV2,
        meta: {
          routeConfig: routeConfig.validateRefreshConfigMissingData,
        },
      },
      {
        path: '/submit-otp',
        name: 'SubmitOtp',
        component: Otp,
        meta: {
          routeConfig: routeConfig.validateRefreshConfigMissingData,
        },
      },
      {
        path: '/login-otp',
        name: 'LoginOtp',
        component: Otp,
        meta: {
          routeConfig: routeConfig.noDocInputOtpRouteConfig,
        },
      },
      {
        path: '/eform-crm',
        name: 'EFormCRM',
        component: EFormCRM,
        meta: {
          routeConfig: routeConfig.preLoginRouteConfigNoDoc,
        },
      },
      {
        path: '/crm/preapprove',
        component: Crm,
        children: makeCrmRoute({
          store,
          features,
        }),
      },
      {
        path: '/dld',
        component: Dld,
        children: makeDldRoute(features),
      },
      {
        path: '/line-ka',
        component: LineOA,
        children: makeLineOARoute({
          store,
          features,
        }),
      },
      {
        path: '/*',
        name: 'catchAll',
        component: PageNotFound,
        meta: {
          routeConfig: routeConfig.allowAllRouteConfig,
        },
      },
    ],
  })
  router.beforeEach(globalGuard)
  router.beforeEach((to, from, next) => {
    const canonical = to.meta.canonical || 'https://promptstart.krungsriauto.com/home'
    document.head.querySelector('link[rel="canonical"]')?.remove()
    const link = document.createElement('link')
    link.setAttribute('rel', 'canonical')
    link.setAttribute('href', canonical)
    document.head.appendChild(link)
    next()
  })
  router.beforeResolve((to, from, next) => {
    store.commit('lineka/setLoading', false)
    next()
    return true
  })
  return router
}
